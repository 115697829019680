import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {Row, Card, Col, Modal, FormGroup, Label, Button, Alert} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from 'react-bootstrap-table2-paginator';
import {Link, withRouter} from "react-router-dom"
import FeatherIcon from "feather-icons-react";
import {isEmpty} from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import Moment from 'react-moment';
import Nulled from "../../components/Null/Nulled";
import SweetAlert from "react-bootstrap-sweetalert"

const ObjectColumns = props => {
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [id, setId] = useState({})
    const handleOpenAlertModal = (id, key) => {
        setId(id)
        setconfirm_alert(true)
    }

    const handleRemove = () => {
        let item = {
            id
        }
        props.handleDelete(item)

        setconfirm_alert(false)
    }

    const paginationOption = {
        sizePerPage: 50,
        totalSize: props.usersCount, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: 'შემდეგი',
        prePageText: 'წინ',
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        },
    }


    const Columns = [
        {
            dataField: "title",
            text: "სათაური",
            sort: true,
        },
        {
            text: "თარიღი",
            formatter: (v, row) => {
                return (
                    <Moment date={row.createdAt} format='DD/MM/YYYY'/>
                )
            }
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>
                    <span
                        onClick={() => {
                            handleOpenAlertModal(row._id)
                        }}
                        style={{cursor: "pointer"}}
                        className="text-danger font-size-24 px-3">
                       <i className="uil-trash"></i>
                  </span>

                </>
            )
        }
    ]
    return (
        <>
            {
                !isEmpty(props.usersArray)
                    ?
                    <>
                        <PaginationProvider
                            pagination={paginationFactory(paginationOption)}
                        >
                            {
                                ({
                                     paginationProps,
                                     paginationTableProps
                                 }) => (
                                    <>
                                        <Row>
                                            <BootstrapTable
                                                keyField="_id"
                                                data={props.usersArray}
                                                columns={Columns}
                                                {...paginationTableProps}
                                            />
                                            <div className="pagination" style={{padding:'11px'}}>
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </Row>


                                    </>
                                )
                            }
                        </PaginationProvider>
                    </>
                    : <Nulled/>
            }
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}


export default ObjectColumns

