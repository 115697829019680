import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
} from "reactstrap"

import Dropzone from "react-dropzone";
import {notification} from 'antd';
import {useFormik} from "formik"
import * as Yup from "yup"

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";


const AddPartner = (props) => {

    document.title = "პარტნიორის დამატება"


    const [selectedFilesErr3, setSelectedFilesErr3] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])



    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {
        files.map(file => {
                if (file.size >= 3022117) {
                    setSelectedFilesErr3(true)
                    setselectedFiles([])
                    return false
                } else {
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                }
            }
        )
        setselectedFiles(files)
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',

        },
        // validationSchema: Yup.object(),
        onSubmit: (values) => {
            let item = {
                title: values.title,
            }
            let formData = new FormData();
            formData.append('file', selectedFiles[0]);
            formData.append('inputs', JSON.stringify(item));

            createBlog(formData)
        }
    })


    async function createBlog(item) {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/partner/create`, {
                method: "POST",
                body: item
            });
            const Data = await response.json();

            if (!Data.success) {
                ErrorResult('error')
            } else {
                SuccessResult('success')
                setSelectedFilesErr3(false)
                setselectedFiles([])
                validation.resetForm();
            }

        } catch (error) {
            ErrorResult('error')
            throw error;
        } finally {
            // setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="პარტნიორის დამატება"/>

                    <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                        }}
                    >

                        <Row>
                            <Col lg="12">
                                <div className="mb-3">
                                    <Label className="form-label">სათაური</Label>
                                    <Input
                                        name="title"
                                        className="form-control"
                                        type="text"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.title || ""}
                                        invalid={
                                            validation.touched.title && validation.errors.title ? true : false
                                        }
                                    />
                                    {validation.touched.title && validation.errors.title ? (
                                        <FormFeedback
                                            type="invalid">{validation.errors.title}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="mb-3">
                                    <Dropzone
                                        accept="image/*,application/pdf"
                                        onDrop={acceptedFiles => {
                                            handleAcceptedFiles(acceptedFiles)
                                        }}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div className="dropzone">
                                                <div
                                                    style={{
                                                        fontSize: '17px'
                                                    }}
                                                    className="dz-message needsclick"
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <p> ატვირთეთ ფოტოსურათი</p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {
                                        selectedFilesErr3
                                            ? <span style={{color: 'red'}}><i> ფაილის ზომა არ უნდა აღემატებოდეს 3 მბ</i></span>
                                            : null
                                    }
                                    <div className="dropzone-previews mt-3" id="file-previews">
                                        {selectedFiles.map((f, i) => {
                                            return (
                                                <Card
                                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                    key={i + "-file"}
                                                >
                                                    <div className="p-2">
                                                        <Row className="align-items-center">
                                                            {/* <Col className="col-auto">
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={f.preview}
                                                                                />
                                                                            </Col>*/}
                                                            <Col>
                                                                <Link
                                                                    to="#"
                                                                    className="text-muted font-weight-bold"
                                                                >
                                                                    {f.name}
                                                                </Link>
                                                                <p className="mb-0">
                                                                    <strong>{f.formattedSize}</strong>
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Card>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Col>

                        </Row>


                        <Row>
                            <Col lg="6">
                                <button
                                    className="btn btn-primary w-100 waves-effect waves-light"
                                    type="submit"
                                >
                                    დამატება
                                </button>
                            </Col>
                        </Row>
                    </Form>


                </Container>
            </div>
        </React.Fragment>
    );


}

export default AddPartner

