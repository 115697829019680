import {
    GET_ALL_INFORMATION_REQUEST,
    GET_DASHBOARD_REQUEST
} from "./actionTypes"

export const getAllInformation = () => ({
    type: GET_ALL_INFORMATION_REQUEST
})

export const getDashboardInformation = () => ({
    type: GET_DASHBOARD_REQUEST
})
