import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux"
import {Row, Card, Col, Modal, FormGroup, Label, Button, Spinner, Alert} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from "react-bootstrap-table2-paginator"
import {isEmpty} from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import Moment from 'react-moment';
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert"
import Nulled from "../../components/Null/Nulled"
import {useNavigate} from "react-router-dom";


const ObjectColumns = props => {

    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [user, setUser] = useState({})
    const [error, setError] = useState(false);
    const [leadDetail, setLeadDetail] = useState({})
    const [leadParams, setLeadParams] = useState({})
    const [key, setKey] = useState({})
    const [id, setId] = useState({})



    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }


    const handleRemove = () => {
        let item = {
            id
        }
        props.handleDelete(item)

        setconfirm_alert(false)
    }


    const openEditPage = (id) =>{
        history(`/edit-blog/${id}`);
    }


    const paginationOption = {
        sizePerPage: 100,
        totalSize: props.usersCount - 100, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: "შემდეგი",
        prePageText: "წინ",
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        }
    }



    const Columns = [
        {
            dataField: "title",
            text: "სათაური",
            sort: true,
        },
        {
            text: "თარიღი",
            formatter: (v, row) => {
                return (
                    <Moment date={row.createdAt} format='DD/MM/YYYY'/>
                )
            }
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>

                    {/*   <span
                        onClick={() => {
                            openEditPage(row._id)
                        }}
                        style={{cursor: "pointer"}}
                        className="text-primary font-size-24 px-3">
                       <i className="uil-edit"></i>
                  </span>*/}
                    <span
                        onClick={() => {
                            handleOpenAlertModal(row._id)
                        }}
                        style={{cursor: "pointer"}}
                        className="text-danger font-size-24 px-3">
                       <i className="uil-trash"></i>
                  </span>

                </>
            )
        }
    ]


    return (
        <>
            {
                !isEmpty(props.array)
                    ?
                    <>
                        <PaginationProvider
                            pagination={paginationFactory(paginationOption)}
                        >
                            {
                                ({
                                     paginationProps,
                                     paginationTableProps
                                 }) => (
                                    <Row>
                                        <BootstrapTable
                                            keyField="_id"
                                            data={props.array}
                                            columns={Columns}
                                            {...paginationTableProps}
                                        />
                                    </Row>
                                )
                            }
                        </PaginationProvider>
                    </>
                    : <Nulled/>
            }

            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}


export default ObjectColumns
