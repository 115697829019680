import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux"
import {Row, Card, Col, Modal, FormGroup, Label, Button, Spinner, Alert} from "reactstrap"
import paginationFactory, {PaginationListStandalone, PaginationProvider} from "react-bootstrap-table2-paginator"
import {isEmpty} from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import Moment from 'react-moment';
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert"
import Nulled from "../../components/Null/Nulled"
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";

const ObjectColumns = props => {

    const [error, setError] = useState(false);
    const [getData, setGetData] = useState({})

    const [isLoading, setIsLoading] = useState(true);
    const [modal_xlarge, setmodal_xlarge] = useState(false)
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [id, setId] = useState({})

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [date, setDate] = useState('')
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState('')
    const [comment, setComment] = useState('')

    const handleOpenAlertModal = (id, key) => {
        setId(id)
        setconfirm_alert(true)
    }


    const handleRemove = () => {
        let item = {
            id
        }
        props.handleDelete(item)
        setconfirm_alert(false)
    }



    const paginationOption = {
        sizePerPage: 100,
        totalSize: props.usersCount - 100, // replace later with size(customerList),
        hideSizePerPage: true,
        alwaysShowAllBtns: false,

        nextPageText: "შემდეგი",
        prePageText: "წინ",
        onPageChange: function (page, sizePerPage) {
            props.pagination(page)
        }
    }


    const Columns = [
        {
            dataField: "name",
            text: "სახელი",
            sort: true,
        },
        {
            dataField: "phone",
            text: "ტელეფონი",
            sort: true,
        },
        {
            dataField: "date",
            text: "შეკ. თარიღი",
            sort: true,
        },
        {
            dataField: "message",
            text: "შეტყობინება",
            sort: true,
        },
        {
            dataField: "status",
            text: "სტატუსი",
            sort: true,
        },
        {
            text: "თარიღი",
            formatter: (v, row) => {
                return (
                    <Moment date={row.createdAt} format='DD/MM/YYYY'/>
                )
            }
        },
        {
            dataField: "_id",
            text: "Actions",
            formatter: (cellContent, row, key) => (
                <>

                 <span
                     style={{cursor: 'pointer'}}
                     onClick={() => {
                         tog_large(row._id, key)
                     }}
                     className="px-3 text-primary">
                         <FeatherIcon icon="edit" className="icon-xs icon me-2 text-pending"/>
                     </span>
                    <span
                        onClick={() => {
                            handleOpenAlertModal(row._id)
                        }}
                        style={{cursor: "pointer"}}
                        className="text-danger font-size-24 px-3">
                       <i className="uil-trash"></i>
                  </span>

                </>
            )
        }
    ]

    function tog_large(id, key) {
        setmodal_xlarge(!modal_xlarge)
        if (modal_xlarge === false) {
            let item = {
                id: id
            }
            getDetail(item)
        }else {
            setGetData({})
            setName('')
            setPhone('')
            setDate('')
            setMessage('')
            setStatus('')
            setComment('')
        }
    }


    async function getDetail(item) {
        let jwt = localStorage.getItem("token")
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/request/getDetail/${item.id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                }
            });
            const Data = await response.json();

            setIsLoading(false)
            if (!Data.success) {
                setError(true)
            } else {
                setGetData(Data.result)

                setName(Data.result.name)
                setPhone(Data.result.phone)
                setDate(Data.result.date)
                setMessage(Data.result.message)

                setStatus(Data.result.status)
                setComment(Data.result.comment)
            }

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }



    const handleUpdate = () =>{
        setmodal_xlarge(!modal_xlarge)
        let item = {
            _id : getData._id,
            name,
            phone,
            date,
            message,
            comment,
            status
        }
        props.updateDetail(item)
    }




    return (
        <>
            {
                !isEmpty(props.array)
                    ?
                    <>
                        <Row>
                            <BootstrapTable
                                keyField="_id"
                                data={props.array}
                                columns={Columns}
                            />
                        </Row>


                    </>
                    : <Nulled/>
            }



            <Modal size="xl" isOpen={modal_xlarge} toggle={() => {
                tog_large()
            }}>
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myExtraLargeModalLabel"
                    >
                       დეტალური ინფორმაცია
                        {/*{props.user.userProfile.userUnicId}*/}
                    </h5>
                    <button
                        onClick={() => {
                            setmodal_xlarge(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    {
                        isLoading ? (
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} className="text-center">
                                    <Spinner className="m-1" color="primary"/>
                                </Col>
                            </Row>
                        ) : error ? (
                            <div className="col-span-full ">
                                <Alert color="danger">{error}</Alert>
                            </div>
                        ) : (
                            <>
                                <Row>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom01">სახელი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => setName(e.target.value)}
                                                value={name}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom01">ტელეფონი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => setPhone(e.target.value)}
                                                value={phone}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom01">თარიღი</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                onChange={(e) => setDate(e.target.value)}
                                                value={date}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom01">შეტყობინება</Label>
                                            <textarea
                                                className="form-control"
                                                onChange={(e) => setMessage(e.target.value)}
                                                value={message}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="12">
                                        <hr/>
                                    </Col>
                                    <Col md="12" style={{textAlign:'center'}}>
                                        <h3>დამატებით ინფორმაცია</h3>
                                    </Col>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom01">სტატუსი</Label>
                                            <select
                                                value={status}
                                                onChange={(e) => setStatus(e.target.value)}
                                                className="form-control">
                                                <option value={'ახალი'}>ახალი</option>
                                                <option value={'მიმდინარე'}>მიმდინარე</option>
                                                <option value={'შესრულდა'}>შესრულდა</option>
                                                <option value={'შესრულდა-დაკორექტირდა'}>შესრულდა-დაკორექტირდა</option>
                                                <option value={'გაუქმდა'}>გაუქმდა</option>

                                            </select>
                                        </div>
                                    </Col>

                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label htmlFor="validationCustom01">კომენტარი</Label>
                                            <textarea
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className="mt-4" style={{paddingTop: '5px'}}>
                                            <Button
                                                onClick={handleUpdate}
                                                type="button" color="primary" className="w-md"> დამახსვორება </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </div>
            </Modal>


            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleRemove}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
        </>
    )
}


export default ObjectColumns
