import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Row,
    Col,
    Card,
    CardBody,
    Label,
    Button,
    Container, Alert, Spinner,
} from "reactstrap"
import {notification} from 'antd';
import ObjectColumns from './ObjectColums'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";



const Requests = (props) => {
    document.title = " ივენთები | CRM"

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState([]);
    const [dataCount, setDataCount] = useState(0);

    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(1000)


    useEffect(() => {
        let item = {
            skip: 0,
            limit: limit,
        }

        async function fetchData() {
            await getLeads(item);
        }

        fetchData();
    }, []);

    async function getLeads(item) {
        let jwt = localStorage.getItem("token")
        try {

            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/request/getAll/${item.skip}/${item.limit}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                }
            });
            const Data = await response.json();

            if (!Data.success) {
                setError(true)
            } else {
                setData(Data.result)
                setDataCount(Data.resultCount)
            }

        } catch (error) {
            setError(true)
            throw error;
        } finally {
            setIsLoading(false);
        }
    }

    const handleDelete = (item) => {
        deleteData(item)
    }


    async function deleteData(item) {
        let jwt = localStorage.getItem("token")
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/request/delete`, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(item)
            });
            const Data = await response.json();

            if (!Data.success) {
                setError(true)
            } else {
                setData(prevData => prevData.filter(obj => obj._id !== item.id));
            }

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }

    async function updateDetail(it) {
        let jwt = localStorage.getItem("token")
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/request/update/${it._id}`, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`,
                },
                body: JSON.stringify(it)
            });
            const Data = await response.json();

            if (!Data.success) {
                ErrorResult('error')
            } else {
                SuccessResult('success')
                setData(prevData => {
                    // Создаем новый массив, в котором заменяем элемент с указанным id
                    return prevData.map(item => {
                        if (item._id === it._id) {
                            return { ...item,
                                name: it.name,
                                phone : it.phone,
                                date : it.date,
                                message : it.message,
                                comment : it.comment,
                                status : it.status
                            };
                        }
                        return item;
                    });
                });
            }

        } catch (error) {
            ErrorResult('error')
            throw error;
        } finally {
            // setIsLoading(false);
        }
    }



    const handlePagination = (page) => {
        let item = {
            skip: page,
            limit: limit,
        }
     //   dispatch(getFlights(item))
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="ივენთების სია"/>
                    <Row>
                        <Col lg={12}>
                            {
                                isLoading ? (
                                    <Row className="justify-content-center align-items-center">
                                        <Col lg={12} className="text-center">
                                            <Spinner className="m-1" color="primary"/>
                                        </Col>
                                    </Row>
                                ) : error ? (
                                    <div className="col-span-full ">
                                        <Alert color="danger">Error</Alert>
                                    </div>
                                ) : (
                                    <>
                                        <ObjectColumns
                                            // onRowSelect={onRowSelect}
                                            // onSelectAll={onSelectAll}
                                            pagination={handlePagination}
                                            handleDelete={handleDelete}
                                            updateDetail={updateDetail}
                                            array={data}
                                            arrayCount={dataCount}
                                        />
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );


}

export default Requests

