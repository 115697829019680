import { call, put, takeEvery } from "redux-saga/effects"
import {
    CHECK_ADMIN_REQUEST,
    LOGIN_ADMIN_REQUEST, LOGIN_ADMIN_REQUEST_SUCCESS
} from "./actionTypes"


import {
    signInEmailOrPasswordIncorrect
} from "../../components/Notifications/authentication"
// import { checkOwnerUser } from "./actions"



function* loginAdminSaga({ payload }) {
    try {
        const callBack = yield call(loginAdminSagaAsync, payload)
        if (callBack.success) {
            localStorage.setItem('token', callBack.token);
            localStorage.setItem("authUser", JSON.stringify(callBack.candidate))
            //yield put(checkOwnerUser(payload));
            payload.history("/dashboard")
        } else {
            signInEmailOrPasswordIncorrect("error")
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
        }

    } catch (err) {
        console.error(err)
    }
}


const loginAdminSagaAsync = async (item) => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/auth/signin`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(item.values)
    })
    return await response.json()
}

function* checkSaga({ payload }) {
    try {
        if (localStorage.getItem("token")) {
            const callBack = yield call(checkSagaAsync, payload)
            if (callBack.success) {
                yield put({type: LOGIN_ADMIN_REQUEST_SUCCESS, callBack})
                if (payload.history !== undefined) {
                    payload.history("/dashboard")
                }
            }else {
                localStorage.removeItem("token")
                localStorage.removeItem("authUser")
             //   payload.history.push("/login")
            }
        }


    } catch (err) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        window.location.href = "/login";
        console.error(err)
    }
}


const checkSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/auth/check`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
    })
    return await response.json()
}

function* authSaga() {
    yield takeEvery(LOGIN_ADMIN_REQUEST, loginAdminSaga)
    yield takeEvery(CHECK_ADMIN_REQUEST, checkSaga)
}

export default authSaga
