import {notification} from 'antd';

const getNotificationStyle = type => {
    return {
        success: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #b7eb8f',
            backgroundColor: '#f6ffed'
        },
        warning: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #ffe58f',
            backgroundColor: '#fffbe6'
        },
        error: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #ffa39e',
            backgroundColor: '#fff1f0',
            width :'500px'
        },
        info: {
            color: 'rgba(0, 0, 0, 0.65)',
            border: '1px solid #91d5ff',
            backgroundColor: '#e6f7ff'
        }
    }[type]
}

export const SuccessResult = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით განხორციელდა',
    });
};

export const ErrorResult = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'The operation was failed',
    });
};

export const GlobalErrorResult = type => {
    notification[type]({
        message: 'Error!',
        duration: 10000,
        description: 'An error has occurred. Contact the IT department',
        placement:'top',
        style: getNotificationStyle(type),
    });
};
