import React, {useEffect, useState} from "react"
import {connect, useDispatch} from "react-redux";
import {
    Card,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback, Spinner, Alert,
} from "reactstrap"

import Dropzone from "react-dropzone";
import {notification} from 'antd';
import {useFormik} from "formik"
import * as Yup from "yup"
import {useParams} from 'react-router-dom';
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Link} from "react-router-dom";
import {ErrorResult, SuccessResult} from "../../components/Notifications/Messages";

const Edit = (props) => {

    document.title = "ბლოგის რედაქტირება"
    const {id} = useParams();


    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const [error, setError] = useState(false);


    const [descriptionKa, setDescriptionKa] = useState(EditorState.createEmpty())
    const [descriptionEn, setDescriptionEn] = useState(EditorState.createEmpty())

    const [selectedFilesErr3, setSelectedFilesErr3] = useState(false)
    const [selectedFiles, setselectedFiles] = useState([])

    const onEditorStateChangeDescriptionKa = editorState => {
        setDescriptionKa(editorState)
    }
    const onEditorStateChangeDescriptionEn = editorState => {
        setDescriptionEn(editorState)
    }


    useEffect(() => {
        let item = {
            id
        }
        getDetail(item)
    }, [])

    useEffect(() => {
        if (data?.descriptionKa) {
            setDescriptionKa(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data?.descriptionKa)
                    ))
            )
        }
        if (data?.descriptionEn) {
            setDescriptionEn(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(data?.descriptionEn)
                    ))
            )
        }


    }, [data.descriptionEn])

    async function getDetail(item) {
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/blog/getDetail/${item.id}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            });
            const Data = await response.json();
            setIsLoading(false)
            if (!Data.success) {
                setError(true)
            } else {
                setData(Data.result)
            }

        } catch (error) {
            throw error;
        } finally {
            setIsLoading(false);
        }
    }


    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function handleAcceptedFiles(files) {
        files.map(file => {
                if (file.size >= 3022117) {
                    setSelectedFilesErr3(true)
                    setselectedFiles([])
                    return false
                } else {
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                    })
                }
            }
        )
        setselectedFiles(files)
    }


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            titleKa: data.titleKa || '',
            titleEn: data.titleEn || '',
            miniDescriptionKa: data.miniDescriptionKa || '',
            miniDescriptionEn: data.miniDescriptionEn || '',

        },
        // validationSchema: Yup.object(),
        onSubmit: (values) => {
            let item = {
                titleKa: values.titleKa,
                titleEn: values.titleEn,
                miniDescriptionKa: values.miniDescriptionKa,
                miniDescriptionEn: values.miniDescriptionEn,
                descriptionKa: draftToHtml(convertToRaw(descriptionKa.getCurrentContent())),
                descriptionEn: draftToHtml(convertToRaw(descriptionEn.getCurrentContent())),
            }

            let formData = new FormData();
            formData.append('file', selectedFiles[0]);
            formData.append('inputs', JSON.stringify(item));

            updateBlog(formData)
        }
    })


    async function updateBlog(item) {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api3/blog/update/${id}`, {
                method: "PUT",
                body: item
            });
            const Data = await response.json();

            if (!Data.success) {
                ErrorResult('error')
            } else {
                SuccessResult('success')
            }

        } catch (error) {
            ErrorResult('error')
            throw error;
        } finally {
            // setIsLoading(false);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="" breadcrumbItem="Events"/>


                    {
                        isLoading ? (
                            <Row className="justify-content-center align-items-center">
                                <Col lg={12} className="text-center">
                                    <Spinner className="m-1" color="primary"/>
                                </Col>
                            </Row>
                        ) : error ? (
                            <div className="col-span-full ">
                                <Alert color="danger">Error</Alert>
                            </div>
                        ) : (
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    validation.handleSubmit()
                                    return false
                                }}
                            >

                                <Row>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">სათაური (ქართულად)</Label>
                                            <Input
                                                name="titleKa"
                                                className="form-control"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.titleKa || ""}
                                                invalid={
                                                    validation.touched.titleKa && validation.errors.titleKa ? true : false
                                                }
                                            />
                                            {validation.touched.titleKa && validation.errors.titleKa ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.titleKa}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">სათაური (ინგლისურად)</Label>
                                            <Input
                                                name="titleEn"
                                                className="form-control"
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.titleEn || ""}
                                                invalid={
                                                    validation.touched.titleEn && validation.errors.titleEn ? true : false
                                                }
                                            />
                                            {validation.touched.titleEn && validation.errors.titleEn ? (
                                                <FormFeedback
                                                    type="invalid">{validation.errors.titleEn}</FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>


                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">მცირე აღწერა (ქართულად)</Label>
                                            <Input
                                                name="miniDescriptionKa"
                                                className="form-control"
                                                type="textarea"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.miniDescriptionKa || ""}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="mb-3">
                                            <Label className="form-label">მცირე აღწერა (ინგლისურად)</Label>
                                            <Input
                                                name="miniDescriptionEn"
                                                className="form-control"
                                                type="textarea"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.miniDescriptionEn || ""}
                                            />
                                        </div>
                                    </Col>

                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label">აღწერილობა ქართულად</Label>
                                            <Editor
                                                editorState={descriptionKa}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                onEditorStateChange={onEditorStateChangeDescriptionKa}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <div className="mb-3">
                                            <Label className="form-label">აღწერილობა ინგლისურად</Label>
                                            <Editor
                                                editorState={descriptionEn}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                onEditorStateChange={onEditorStateChangeDescriptionEn}
                                            />
                                        </div>
                                    </Col>

                                    <Col md={8}>
                                        <div className="mb-3">
                                            <Dropzone
                                                accept="image/*,application/pdf"
                                                onDrop={acceptedFiles => {
                                                    handleAcceptedFiles(acceptedFiles)
                                                }}
                                            >
                                                {({getRootProps, getInputProps}) => (
                                                    <div className="dropzone">
                                                        <div
                                                            style={{
                                                                fontSize: '17px'
                                                            }}
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <span> ატვირთეთ ფოტოსურათი</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {
                                                selectedFilesErr3
                                                    ?
                                                    <span style={{color: 'red'}}><i> ფაილის ზომა არ უნდა აღემატებოდეს 3 მბ</i></span>
                                                    : null
                                            }
                                            <div className="dropzone-previews mt-3" id="file-previews">
                                                {selectedFiles.map((f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    {/* <Col className="col-auto">
                                                                                <img
                                                                                    data-dz-thumbnail=""
                                                                                    height="80"
                                                                                    className="avatar-sm rounded bg-light"
                                                                                    alt={f.name}
                                                                                    src={f.preview}
                                                                                />
                                                                            </Col>*/}
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg="4">
                                        <img
                                            src={data.image}
                                        />
                                    </Col>
                                </Row>


                                <Row>
                                    <Col lg="6">
                                        <button
                                            className="btn btn-primary w-100 waves-effect waves-light"
                                            type="submit"
                                        >
                                            განახლება
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                        )
                    }


                </Container>
            </div>
        </React.Fragment>
    );


}

export default Edit

